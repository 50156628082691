import { LazyLoadBackgroundImage } from '@/components/lazy-load-background-image';
import { NewsList } from '@/components/news-list';
import { configs } from '@/constants/configs';
import { DefaultLayout } from '@/layouts/default-layout/DefaultLayout';
import { StaticQueryDocument, graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import '../styles/global.scss';
import '../styles/news-page.scss';

const pageName: string = 'News';

const query: StaticQueryDocument = graphql`
	query {
		bgImage: file(relativePath: { eq: "images/news/background.png" }) {
			childImageSharp {
				gatsbyImageData(
					quality: 90
          placeholder: BLURRED
				)
			}
		}
	}
`;

export default function News() {
	const { bgImage } = useStaticQuery(query);
	return (
		<DefaultLayout
			className="news-page"
			pageTitle={`${configs.defaultPageTitle} - ${pageName}`}
		>
			<LazyLoadBackgroundImage
				Tag="div"
				imageGraphql={bgImage}
				className="news-page__header-container"
				data-content={pageName}
			>
				<div className="news-page__header-wrapper" data-content={pageName}>
          <h2 className="news-page__header atmospheric-text">
            {pageName}
          </h2>
        </div>
			</LazyLoadBackgroundImage>
			<div className="news-page__static-image">
				<StaticImage
					aria-hidden="true"
					src={'../assets/images/news/gallery1.png'}
					loading="eager"
					placeholder="blurred"
					alt="gallery"
				/>
			</div>
			{/* <NewsGallery images={NEWS_GALLERY} /> */}
			<NewsList />
		</DefaultLayout>
	);
}
