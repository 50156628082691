import React from 'react';
import Logo from '@images/logo.png';
import './page-footer.scss';
import { configs } from '@/constants/configs';
import { NewsletterSignupForm } from '../newsletter-signup-form';
import { useGlobalSetting } from '@/contexts/GlobalSettingContext';

const PageFooter: React.FC = () => {
	const { toggleModal } = useGlobalSetting();
	
	React.useEffect(() => {
		const params = new URLSearchParams(location.search);
		if (params.get('signedup') === 'true') {
			toggleModal(() => ({
				toggle: true,
				className: 'alert-dialog',
				body: <>
					<h2 className="atmospheric-text">Thank you!</h2>
					<p>Thank you for signing up for our newsletter!</p>
				</>,
			}));
		}
	}, []);

	return (
		<footer className="page-footer">
			<NewsletterSignupForm />
			<div className="page-footer__logo" aria-hidden="true">
				<img src={Logo} alt="playground logo" />
			</div>
			<p className="corbel-text">
				PLAYGROUND is a virtual reality platform powered by POLYGON
				blockchain.
			</p>
			<nav aria-labelledby="footer-link">
				<h2 id="footer-link" className="atmospheric-text">
					Quick links
				</h2>
				<ul className="corbel-text">
					{configs.footerLinks.map((item: SiteLink, key: number) => (
						<li key={key}>
							<a href={item.url}>{item.name}</a>
						</li>
					))}
				</ul>
			</nav>
		</footer>
	);
};

export { PageFooter };
