import React from 'react';
import { NewsItem, NewsItemProps } from './NewsItem';
import './news-list.scss';
import { useGlobalSetting } from '@/contexts/GlobalSettingContext';
import axios, { AxiosResponse } from 'axios';
import { APP_MEDIUM_API } from 'gatsby-env-variables';

const NewsList: React.FC = () => {
	const { toggleModal, toggleGlobalLoader } = useGlobalSetting();
	const [news, setNews] = React.useState<NewsItemProps[]>([]);
	const onReadMoreClick = (index: number) => {
		const item = news[index];
		toggleModal({
			className: 'article-modal',
			header: item.title,
			body: <NewModalBody {...item} />,
			toggle: true,
			withFooter: false,
		});
	};
	const loadContent = () => {
		toggleGlobalLoader(true);
		axios.get(APP_MEDIUM_API)
			.then((response: AxiosResponse<MediumResponse>) => {
				setNews(response.data.items)
			})
			.finally(() => toggleGlobalLoader(false))
	}

	React.useEffect(() => {
		loadContent();
	}, []);
	return (
		<ul className="news-list">
			{news.map((item: NewsItemProps, index: number) => (
				<NewsItem
					{...item}
					key={index}
					onReadMoreClick={() => onReadMoreClick(index)}
				/>
			))}
		</ul>
	);
};

const NewModalBody: React.FC<NewsItemProps> = ({
	thumbnail,
	content,
	title,
}: NewsItemProps) => {
	return (
		<div className="news-modal-body">
			<h2 className="atmospheric-text">{title}</h2>
			<div
				className="news-modal-body__main-content"
				dangerouslySetInnerHTML={{ __html: content }}
			/>
		</div>
	);
};

export { NewsList };
