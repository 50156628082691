import React from 'react';
import moment from 'moment';
import './news-item.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { useGlobalSetting } from '@/contexts/GlobalSettingContext';

export interface NewsItemProps extends MediumArticle {
  onReadMoreClick?: () => void;
}

const NewsItem: React.FC<NewsItemProps> = ({ title, pubDate, link, thumbnail, description, onReadMoreClick }: NewsItemProps) => {
  const getFormattedDate = () => {
    return moment(pubDate).format("Do MMMM YYYY");
  };

  return <li className="news-item">
    <article>
      <h3 className="atmospheric-text hidden-md">{title}</h3>
      <figure className="news-item__image">
        <div className="image-wrapper">
          <img src={thumbnail} alt={thumbnail} /> 
        </div> 
      </figure>
      <div className="news-item__main-content">
        <div className="news-item__date corbel-text">Published on {getFormattedDate()}</div>
        <h3 className="atmospheric-text hidden-sm">{title}</h3>
        <NewsDetails onReadMoreClick={onReadMoreClick} description={description} />
        <a href={link} target="_blank" className="news-item__medium-link">
          <StaticImage
            aria-hidden="true"
            className="link-image"
            src={'../../assets/images/medium-icon.png'}
            loading="eager"
            placeholder="blurred"
            alt="medium"
          />
          <span className="sr-only">To medium</span>
        </a>
      </div>
    </article>
  </li>
};

type NewsDetailsProps = Pick<NewsItemProps, 'description' | 'onReadMoreClick'>;

// TODO: enhance details
const NewsDetails: React.FC<NewsDetailsProps> = ({ description, onReadMoreClick }: NewsDetailsProps) => {
  const truncateString = () => {
    const truncated: string = description.replace(/ *<figure>[^)]*<\/figure> */g, "").substring(0, 200);
    return `${truncated}`;
  }

  return <div className="corbel-text details-wrapper">
    <div dangerouslySetInnerHTML={{ __html: truncateString() }} />
    <button onClick={onReadMoreClick}>...Read more</button>
  </div>
}

export { NewsItem };
