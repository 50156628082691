import { APP_NEWSLETTER_SIGNUP_REDIRECTURL } from 'gatsby-env-variables';
import React from 'react';
import './newsletter-signup-form.scss';

const NewsletterSignupForm: React.FC = () => {
	return (
		<form
			method="post"
			className="page-footer__signup-form"
			acceptCharset="UTF-8"
			action="https://www.aweber.com/scripts/addlead.pl"
		>
      <h3 className="atmospheric-text">Sign up for our newsletter and stay updated.</h3>
			<div aria-hidden="true">
				<input
					type="hidden"
					name="meta_web_form_id"
					value="308252915"
				/>
				<input type="hidden" name="meta_split_id" value="" />
				<input type="hidden" name="listname" value="awlist6289208" />
				<input
					type="hidden"
					name="redirect"
					value={APP_NEWSLETTER_SIGNUP_REDIRECTURL}
					id="redirect_c07d6683617fc550b2a28d5e3b29f757"
				/>

				<input
					type="hidden"
					name="meta_adtracking"
					value="Newsletter_signup"
				/>
				<input type="hidden" name="meta_message" value="1" />
				<input type="hidden" name="meta_required" value="email" />

				<input type="hidden" name="meta_tooltip" value="" />
			</div>
			<div id="af-form-308252915" className="af-form">
				<div id="af-body-308252915" className="af-body af-standards">
					<div className="af-element">
						<label className="sr-only" htmlFor="awf_field-114443958">
							Email:{' '}
						</label>
						<div className="af-textWrap">
							<input
								className="text"
								id="awf_field-114443958"
								type="email"
                required
								name="email"
                placeholder="Your email"
							/>
						</div>
					</div>
					<div className="af-element buttonContainer">
						<button
							className="submit"
							type="submit">
								Submit
						</button>
						<div className="af-clear"></div>
					</div>
				</div>
			</div>
		</form>
	);
};

export { NewsletterSignupForm };
