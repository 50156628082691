import React from 'react';
import clsx from 'clsx';
import { SocialMediaBanner } from '@/components/social-media-banner';
import { PageFooter } from '@/components/page-footer';
import { configs } from '@/constants/configs';
import { RootLayout } from '../root-layout';
import './default-layout.scss';

export interface DefaultLayoutProps {
	children: React.ReactNode;
	className?: string;
	pageTitle?: string;
	pageDescription?: string;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
	children,
	className,
	pageTitle = configs.defaultPageTitle,
	pageDescription = configs.defaultPageDescription,
}: DefaultLayoutProps) => {
	return <RootLayout pageTitle={pageTitle} pageDescription={pageDescription}>
		<main className={clsx(className)}>
			<SocialMediaBanner />
			{children}
		</main>
		<PageFooter />
	</RootLayout>;
};

export { DefaultLayout };
