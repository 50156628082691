import { NAV_SOCIALMEDIALIST } from '@/constants/social-media';
import React from 'react';
import { SocialMediaButton } from '../social-media-button';
import "./social-media-banner.scss";

const SocialMediaBanner: React.FC = () => {
	return (
		<nav className="social-media-banner" aria-labelledby="social media navigation">
			{Object.keys(NAV_SOCIALMEDIALIST).map((item: string) => {
				return (
					<SocialMediaButton
						socialMedia={item as SocialMediaName}
						key={item}
						url={NAV_SOCIALMEDIALIST[item as SocialMediaName] || ''}
					/>
				);
			})}
		</nav>
	);
};

export { SocialMediaBanner };
